<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row hide-print">
                <div class="col-xs-12">
                    <h1 class="h2 margin-bottom-30">Nuova Prenotazione</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-5">
                    <div id="checkbox-product" class="booking-section">
                        <h4>01. Seleziona Sito</h4>

                        <div class="checkbox" v-for="product in productsOptions" :key="product.value">
                            <label>
                                <input type="checkbox" :value="product.value" v-model.number="checkedProducts[product.value]">
                                {{product.name}}
                            </label>
                        </div>
                    </div>
                    <div class="booking-section">
                        <h4 style="margin-bottom:20px;">02. Seleziona Data e Orario</h4>
                        <a v-show="!customizingDate" id="custom_datetime_btn" class="btn btn-default" @click="customizingDate=true"><span class="glyphicon glyphicon-calendar" aria-hidden="true"></span> Personalizza Data e Orario</a>
                        <div class="row" v-show="customizingDate" id="section_date">
                            <div class="col-xs-6">
                                <div class="form-group has-feedback">
                                    <Datetime
                                            id="appointment_repeat_until_date"
                                            type="datetime"
                                            :value-zone="TIMEZONE_DEFAULT"
                                            :zone="TIMEZONE_DEFAULT"
                                            v-model="newReservation.date"
                                            input-class="form-control"
                                    ></Datetime>
                                    <span aria-hidden="true" class="glyphicon glyphicon-calendar form-control-feedback"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="booking-section">
                        <h4>03. Seleziona Tariffa</h4>
                        <div class="row">
                            <div id="container_rates">
                                <div class="row_rate" v-for="(rateInstance, index) in rateInstances" :key="index">
                                    <div class="col-xs-7">
                                        <label>Tariffa</label>
                                        <select class="form-control rate_option" v-model.number="rateInstance.rate">
                                            <option v-for="(rate, index) in ratesOptions" :value="rate.value" :key="index" :disabled="rate.value === undefined">
                                                {{rate.name}} <span v-if="rate.value !== undefined">- €{{rate.price.toFixed(2)}}</span>
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-xs-4">
                                        <div class="form-group">
                                            <label>Quantità</label>
                                            <input name="qunatity" type="number" class="form-control quantity" min="1" v-model.number="rateInstance.quantity" data-number="0">
                                        </div>
                                    </div>
                                    <div class="col-xs-1" v-if="index > 0">
                                        <div class="form-group"><a @click.prevent.stop="removeRateInstance(index)" style="margin-top: 25px;" data-number="1" class="btn btn-danger delete-row">x</a></div>
                                    </div>
                                    <div class="clearfix margin-bottom-10"></div>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <a id="add_row_btn" style="margin-top:10px;" class="btn btn-default" @click.prevent.stop="addNewRateInstance">
                                    <span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Aggiungi Tariffa
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="booking-section">
                        <h4>04. Informazioni Cliente</h4>
                        <div class="row margin-bottom-15">
                            <div class="col-xs-12">
                                <div class="form-group">
                                    <label for="nationality">Nazione*</label>
                                    <SelectCountry @country-changed="countryChanged"></SelectCountry>

                                </div>
                            </div>
                        </div>
                        <a class="btn btn-default margin-bottom-15" role="button" data-toggle="collapse" href="#collapseCustomerData" aria-expanded="false" aria-controls="collapseCustomerData"><span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Inserisci Dati Cliente</a>
                        <div class="collapse" id="collapseCustomerData">
                            <div class="row">
                                <div class="col-xs-6">
                                    <div class="form-group">
                                        <label for="fname">Nome</label>
                                        <input type="text" class="form-control" id="fname" v-model="newReservation.first_name">
                                    </div>
                                </div>
                                <div class="col-xs-6">
                                    <div class="form-group">
                                        <label for="lname">Cognome</label>
                                        <input type="text" class="form-control" id="lname" v-model="newReservation.last_name">
                                    </div>
                                </div>
                            </div>
                            <div class="row margin-top-20 margin-bottom-100">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input type="email" class="form-control" id="email" v-model="newReservation.email">
                                    </div>
                                    <div class="form-group">
                                        <label for="phone">Telefono</label>
                                        <VuePhoneNumberInput
                                                @update="updatePhoneNumber"
                                                no-validator-state
                                                default-country-code="IT"
                                                v-model="phone_number_not_international"
                                                :translations="Utils.getTranslationsPhoneField()"
                                                id="phone"></VuePhoneNumberInput>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-xs-offset-1">
                    <div class="panel panel-default">
                        <div class="panel-heading">Cassa</div>
                        <div class="panel-body">
                            <CashSummaryTable
                                    :estimate-reservation="estimateReservation"
                            ></CashSummaryTable>
                        </div>
                    </div>
                    <div class="panel panel-default">
                        <div class="panel-heading">Modalità Pagamento</div>
                        <div class="panel-body">
                            <p class="alert alert-info" v-show="estimateReservation.price_final === 0" id="no-payment-required">Non è richiesto nessun pagamento</p>
                            <div class="radio" v-for="paymentMethod in paymentOptions" :key="paymentMethod.value">
                                <label>
                                    <input type="radio" :disabled="estimateReservation.price_final === 0" name="optionsPayments" id="cash" :value="paymentMethod.value" v-model="newReservation.payment_mode">
                                    {{paymentMethod.name}}
                                </label>
                            </div>
                            <button id="pay_now_btn" type="button" class="btn btn-lg btn-block btn-primary" @click="createReservation()">
                                <span v-if="estimateReservation.price_final === 0">Crea prenotazione</span>
                                <span v-else>Paga ora €{{estimateReservation.price_final.toFixed(2)}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <GenericChooseModal code="1" title="Informazione" :danger-message="true" message="Devi selezionare almeno un sito per poter creare una prenotazione" :hide-negative-button="true" positive-button-text="Va bene" :visible="visibleModalReservationItemError" @positive-click-generic-modal1="visibleModalReservationItemError = false" @close-generic-modal1="visibleModalReservationItemError = false"/>
      <GenericChooseModal code="2" title="Informazione" :danger-message="true" message="Devi selezionare la nazione prima di continuare" :hide-negative-button="true" positive-button-text="Va bene" :visible="visibleModalCountryError" @positive-click-generic-modal2="visibleModalCountryError = false" @close-generic-modal2="visibleModalCountryError = false"/>
    </ContainerWithMenu>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import CashSummaryTable from "../components/Booking/CashSummaryTable";
    import baseMixin from "../common/baseMixin";
    import {TIMEZONE_DEFAULT} from "../common/constants";
    import {Datetime} from "vue-datetime";
    import 'vue-datetime/dist/vue-datetime.css'
    import {createReservation, estimateReservation} from "../api";
    import SelectCountry from "../components/common/SelectCountry";
    import GenericChooseModal from "../components/modal/GenericChooseModal";


    export default {
        title: "Nuova Prenotazione",
        name: "Booking",
        components: {SelectCountry, CashSummaryTable, ContainerWithMenu, Datetime, VuePhoneNumberInput,GenericChooseModal},
        mixins: [baseMixin],
        data: () => {
            return {
                loading: false,
                productsOptions: [],
                checkedProducts: {},
                customizingDate: false,
                TIMEZONE_DEFAULT: TIMEZONE_DEFAULT,
                paymentOptions: [],
                ratesOptions: [],
                rateInstances: [],
                newReservation: {
                    reservation_items: [],
                    first_name: null,
                    email: null,
                    last_name: null,
                    phone_number: null,
                    payment_mode: null,
                    country: null,
                    date: new Date().toISOString()
                },
                estimateReservation: {
                    price: 0,
                    price_final: 0,
                    price_discount: 0,
                    reservation_item_grouped_by_product: {},
                    discount_people: false
                },
                phone_number_not_international: null,
                visibleModalCountryError: false,
                visibleModalReservationItemError: false,
            }
        },
        methods: {
            async createReservation() {
                this.loading = true;
                try {
                    let responseReservation = await createReservation(
                        this.newReservation.first_name,
                        this.newReservation.last_name,
                        this.newReservation.email,
                        this.newReservation.phone_number,
                        this.newReservation.country,
                        this.newReservation.payment_mode,
                        this.newReservation.reservation_items,
                        this.newReservation.date
                    );
                    console.log(responseReservation);
                    this.$router.push({ name: "reservations" });
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    if(e.response.data.code === "reservation_items_required"){
                      this.visibleModalReservationItemError = true
                    }
                    else if (e.response.data.code === "country_required"){
                      this.visibleModalCountryError = true
                    }
                }
            },
            addNewRateInstance() {
                let rateInstance = {
                    rate: this.Keys.RATES.ADULT.value,
                    quantity: 1
                };
                this.rateInstances.push(rateInstance);
            },
            removeRateInstance(index) {
                this.rateInstances.splice(index, 1);
            },
            updatePhoneNumber(result) {
                this.newReservation.phone_number = result.formatInternational;
            },
            async regenerateReservationItems(checkedProducts, ratesInstance) {
                this.newReservation.reservation_items = [];

                for (const valueRate of ratesInstance) {
                    for (const [keyProduct, valueProduct] of Object.entries(checkedProducts)) {
                        if (valueProduct) {
                            this.newReservation.reservation_items.push(
                                {
                                    "product_id": parseInt(keyProduct),
                                    "rate": valueRate.rate,
                                    "quantity": valueRate.quantity
                                }
                            )
                        }
                    }
                }

                try {
                    this.estimateReservation = await estimateReservation(this.newReservation.reservation_items);
                } catch (e) {
                    console.log(e)
                }

            },
            countryChanged(newCountry) {
                this.newReservation.country = newCountry;
            }
        },
        mounted() {
            this.productsOptions = this.Keys.PRODUCTS_OPTIONS();
            this.ratesOptions = this.Keys.RATES_OPTIONS();
            this.paymentOptions = this.Keys.PAYMENT_MODE_WITHOUT_CREDIT_CARD_OPTIONS();
            this.addNewRateInstance();
            this.newReservation.payment_mode = this.Keys.PAYMENT_MODES.CASH;
        },

        watch: {
            checkedProducts: {
                handler(newCheckedProducts) {
                    this.regenerateReservationItems(newCheckedProducts, this.rateInstances)
                },
                deep: true,
            },
            rateInstances: {
                handler(newRateInstances) {
                    this.regenerateReservationItems(this.checkedProducts, newRateInstances)
                },
                deep: true,
            }
        }
    };
</script>
